import "@hotwired/turbo-rails"
import "./controllers"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as bootstrap from "bootstrap"

import 'startbootstrap-sb-admin/dist/js/scripts.js'
import 'startbootstrap-sb-admin/dist/css/styles.css'
import '@fortawesome/fontawesome-free/js/all';
import 'intl-tel-input/build/js/utils.js';

import AcademicLocationHelper from 'academic_location_helper';
window.AcademicLocationHelper = AcademicLocationHelper;

import PositionValidationHelper from 'position_validation_helper';
window.PositionValidationHelper = PositionValidationHelper;

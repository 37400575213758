import * as locationData from '../../config/data/location.json';

export default class AcademicLocationHelper {
  private jquery: JQueryStatic;

  constructor(jquery: JQueryStatic) {
    this.jquery = jquery;
  }

  resetOptions(field: string, optionList: string[]): void {
    const selectedValue = this.jquery(field).val();
    this.jquery(field).find('option').not(':first').remove();
    optionList.forEach(optionValue => {
      this.jquery(field).append(this.jquery('<option>', {
        value: optionValue,
        text: optionValue
      }));
    });
    this.jquery(field).val(selectedValue);
  }

  changeSelectVisibility(field: string, selectVisible: boolean, suffix = ''): void {
    if (selectVisible) {
      this.enableElement(this.jquery(field.concat('-select', suffix)))
      this.disableElement(this.jquery(field.concat('-text', suffix)))
    } else {
      this.enableElement(this.jquery(field.concat('-text', suffix)))
      this.disableElement(this.jquery(field.concat('-select', suffix)))
    }
  }

  enableElement(element: JQuery): void {
    element.show();
    element.attr('required', 'required');
    if (element.attr('name'))
      element.attr('name', element.attr('name').replace(/-unused/g, ''));

    if (element.hasClass('select2'))
      element.parent().show();
  }

  disableElement(element: JQuery): void {
    element.hide();
    element.removeAttr('required');
    if (element.attr('name'))
      element.attr('name', element.attr('name').replace(/]/, '-unused]'));

    if (element.hasClass('select2'))
      element.parent().hide();
  }

  onChangeRadioButton(targetField: string, targetValue: string, suffix = ''): (Event) => void {
    return (event) => {
      this.updateVisibilityWhenFormationAbroad(targetField, targetValue, event.target.value, suffix)
    }
  }

  onChangeCountryField(targetStateField: string, targetCityField: string, targetValue: string, suffix = ''): (Event) => void {
    return (event) => {
      this.updateVisibilityWhenCountryChanges(targetStateField, targetCityField, targetValue, event.target.value, suffix)
    }
  }

  onChangeStateField(targetCityField: string, suffix = ''): (Event) => void {
    return (event) => {
      this.updateCityField(event.target.value, targetCityField, suffix)
    }
  }

  onLoadStateSelectFilter(stateSelector: string, citySelector: string, stateValue: string, cityValue: string): void {
    this.resetOptions(stateSelector, locationData.states.br);
    if (stateValue !== '') {
      this.jquery(stateSelector).val(stateValue);
      if (cityValue !== '') {
        this.resetOptions(citySelector, locationData.cities.br[stateValue]);
        this.jquery(citySelector).val(cityValue);
      }
    }
  }

  onChangeStateSelectFilter(citySelector: string, value: string): void {
    let cityValue = locationData.cities.br[value];
    if (cityValue === undefined) {
      cityValue = [];
    }
    this.resetOptions(citySelector, cityValue);
  }

  onLoadSetup(): void {
    this.resetOptions('.country-field', locationData.countries)
    this.resetOptions('.state-field-select', locationData.states.br)
    this.changeSelectVisibility('.state-field', this.jquery('.country-field').length === 0)
    this.changeSelectVisibility('.city-field', this.jquery('.country-field').length === 0)
  }

  onEditLoadSetup(suffix: string, selectVisible: boolean, state = ''): void {
    this.resetOptions('.country-field-' + suffix, locationData.countries)
    this.resetOptions('.state-field-select-' + suffix, locationData.states.br)
    this.changeSelectVisibility('.state-field', selectVisible, '-' + suffix)
    this.changeSelectVisibility('.city-field', selectVisible, '-' + suffix)
    this.updateCityField(state, '.city-field', '-' + suffix)
  }

  updateCityField(state: string, targetCityField: string, suffix = ''): void {
    if (state !== '') {
      this.resetOptions(`${targetCityField}-select${suffix}`, locationData.cities.br[state])
    }
    this.changeSelectVisibility(targetCityField, state !== '', suffix)
  }

  updateVisibilityWhenFormationAbroad(targetField: string, targetValue: string, country: string, suffix = ''): void {
    this.changeSelectVisibility(targetField, country == targetValue, suffix)
    this.changeSelectVisibility('.city-field', country == targetValue && this.jquery('.state-field-select' + suffix).val() !== '', suffix);
  }

  updateVisibilityWhenCountryChanges(targetStateField: string, targetCityField: string, targetValue: string, selectedValue: string, suffix = ''): void {
    const is_selected_value_empty = this.jquery(targetStateField + "-select" + suffix).val() !== '';

    this.changeSelectVisibility(targetStateField, selectedValue == targetValue, suffix)
    this.changeSelectVisibility(targetCityField, selectedValue == targetValue && is_selected_value_empty, suffix)
  }
}

export default class PositionValidationHelper {
  private jquery: JQueryStatic;

  constructor(jquery: JQueryStatic) {
    this.jquery = jquery;
  }

  validateFormEnableButton(formId: string, buttonId: string): void {
    console.log('oi')
    const form = this.jquery(formId);

    if (form[0].checkValidity() && this.validEmailConfirmation('#position_email', '#email_confirmation')) {
      this.jquery(buttonId).attr('disabled', null);
      return;
    }
    this.jquery(buttonId).attr('disabled', 'disabled');
  }

  validEmailConfirmation(positionEmailId: string, emailConfirmationId: string): boolean {
    const email = this.jquery(positionEmailId);
    const confirmation = this.jquery(emailConfirmationId);

    if (email.val() != confirmation.val()) {
      return false;
    }

    return true;
  }
}
